import React from 'react'
import BreadCrumbTitle from '../elements/breadcrumb-title'
import Image from '../image';

const PlatformCloud = ({ data }) => {
    return (
        <section className={`platform-cloud bg-${data.backgroundColour}`}>
            <BreadCrumbTitle data={data.title} />
            <div className="platform-cloud__images-wrapper">
                {data.images.map(img => (
                    <div key={img.id} className="platform-cloud__image-wrap">
                        <Image media={img} className="" />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default PlatformCloud;