import React from 'react'
import CustomLink from './custom-link'
import Image from '../image'
import StylisedText from './stylised-text'

const QuickLink = ({ link }) => {
    
    return (
        <CustomLink link={link}>
            <div className="quicklink">
                <div className="quicklink__title"><StylisedText data={link.title} type="p" /></div>
                <div className="quicklink__icon"><Image media={link.icon} className="" /></div>
            </div>
        </CustomLink>
    );
}

export default QuickLink;