import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CareerCard } from "./elements/card"
import BreadcrumbTitle from "./elements/breadcrumb-title"
import StylisedText from "./elements/stylised-text"

const Careers = props => {
  const data = useStaticQuery(query)
  const { edges } = data.allStrapiCareer
  return (
    <section className="careers">
      <div className="careers__container">
        <div className="careers__text">
          <BreadcrumbTitle data={props.data.breadcrumbTitle} />
          <StylisedText data={props.data.title} type="h3" />
        </div>
        <div className="careers__cards">
          {edges.map((career, id) => (
            <CareerCard data={career.node} key={id} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Careers

const query = graphql`
  query {
    allStrapiCareer(sort: {fields: [publishDate], order: DESC}) {
      edges {
        node {
          id
          slug
          title
          blurb
          publishDate
          cardBackground {
            ext
            url
          }
        }
      }
    }
  }
`
