import React from 'react'
import BreadcrumbTitle from '../elements/breadcrumb-title'
import EmailLink from '../elements/email-link'
import StylizedText from '../elements/stylised-text'

const TextFeature = ({ data }) => {

    return (
        <section className="textfeature" style={{ background: data.backgroundGradient }}>
            <div className="textfeature__container">
                <BreadcrumbTitle data={data.breadcrumbTitle} />
                <StylizedText data={data.title} type="h3" />
                <div className="textfeature__button-wrap">
                    <EmailLink data={data.emailLink} />
                </div>
            </div>
            
        </section>
    )
}

export default TextFeature;