import React from "react"
import StylizedText from "../elements/stylised-text"
import StatisticItem from "../elements/statistic-item"

const StatisticGrid = ({ data }) => {
  return (
    <section className={`statisticGrid ${data.backgroundColour}`}>
      <div className="statisticGrid__outer">
        <div className="statisticGrid__inner">
          <div className="statisticGrid__text-wrap">
            {data.title ? <StylizedText data={data.title} type="h3" /> : ""}
            {data.description ? (
              <p className="statisticGrid__description">{data.description}</p>
            ) : (
              ""
            )}
          </div>
          <div className="statisticGrid__graph-grid">
            {data.items
              ? data.items.map(item => <StatisticItem data={item} key={item.id} />)
              : ""}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StatisticGrid
