import React from "react"

const LineBreak = ({ data }) => {
  return (
    <div className="linebreak">
      <hr
        className={`linebreak__line linebreak__line--h-${data.lineheight}`}
      ></hr>
    </div>
  )
}

export default LineBreak
