import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import RotationalFeature from "./rotational-feature"

const CaseStudyRotationalBanner = ({ data }) => {
  const queryData = useStaticQuery(query)

  const { edges } = queryData.allStrapiCasestudy

  let promoItems = []
  edges.map(study => promoItems.push(study.node.cardMetadata.promoItem))
  Object.assign(data, { promoitems: promoItems })

  return edges && <RotationalFeature data={data} key={data.id} />
}

export default CaseStudyRotationalBanner

const query = graphql`
  query {
    allStrapiCasestudy {
      edges {
        node {
          id
          title
          slug
          blurb
          metadata {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
          contentSections
          cardMetadata {
            promoItem {
              backgroundGradient
              breadcrumbTitle {
                dashColour
                colour
                id
                title
              }
              background {
                ext
                url
                formats {
                  large {
                    url
                  }
                }
              }
              button {
                id
                newTab
                text
                type
                url
              }
              description
              id
              title {
                colour
                id
                style
                text
              }
            }
            id
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`
