import React from "react"
import PropTypes from "prop-types";
import Markdown from "react-markdown";

const RichText = ({ data }) => {
    return (
        <div className="container mx-auto py-12 max-w-screen-lg">
            <div className="prose prose-sm max-w-none">
                {/* TODO: Maybe create another component for RawText */}
                {/* <div dangerouslySetInnerHTML={{ __html: data.content }}></div>  */}
                <Markdown source={data.content} />
            </div>
        </div>

    );
};

RichText.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.string,
    }),
};

export default RichText;
