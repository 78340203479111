import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import StylisedText from "../elements/stylised-text"

import OpeningQuotes from "../../images/openingQuotes.svg"
import "swiper/css"
import "swiper/css/navigation"

import Image from "../image"

const TestimonialContent = ({ key, data }) => {
  return (
    <div className="testimonials__content" key={key}>
      <p className="testimonials__content__description">{data.description}</p>
      <div className="testimonials__img-wrapper">
        <Image media={data.avatar} className="testimonials__image" />
      </div>
      <span className="testimonials__content__name">{data.name}</span>
      <span className="testimonials__content__title gray">{data.title}</span>
    </div>
  )
}

const Testimonials = ({ data }) => {
  return (
    <section className={`testimonials bg-${data.backgroundColour}`}>
      <div className="testimonials__container">
        <div className="testimonials__swiper-wrapper">
          <OpeningQuotes className="testimonials__quotes" />
          <Swiper
            modules={[Navigation]}
            spaceBetween={0}
            slidesPerView={1}
            speed={800}
            loop={true}
            navigation
            slideActiveClass="true"
            className="w-full"
          >
            {data.testimonials.map(testimonial => (
              <SwiperSlide key={testimonial.id}>
                <TestimonialContent data={testimonial} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="testimonials__outer">
          <div className="testimonials__inner">
          <BreadcrumbTitle data={data.breadcrumbTitle} />
          <StylisedText data={data.title} type="h3" />
          <p className="testimonials__inner__description">
            {data.description}
          </p>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default Testimonials
