import React from "react"
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import emojione from 'emojione';

const EmojiText = ({ data }) => {
    const emojified = emojione.shortnameToUnicode(data.content);
    return (
        <div className="container mx-auto max-w-screen-lg">
            <Markdown source={emojified} />
        </div>

    );
};

EmojiText.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.string,
    }),
};

export default EmojiText;
