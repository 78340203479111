import React, { useEffect, createRef, useRef, useState } from "react"
import { debounce } from "@/utils/common"
import classNames from "classnames"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import StylisedText from "../elements/stylised-text"
import ButtonLink from "../elements/button-link"
import lottie from "lottie-web"

const isBrowser = typeof window !== "undefined"

const ImageFeature = ({ data }) => {
    const imageRef = useRef(null)
    const imageWrapperRef = useRef(null)
    const animationContainer = createRef()

    let height = isBrowser ? window.innerHeight : 0
    let width = isBrowser ? window.innerWidth : 0
    let variationHeight = data.variation === 'large' ? 740 : 580;

    if (typeof window !== `undefined`) {
        height = window.innerHeight
        width = window.innerWidth
    }
    // See default state of dimensions
    const [featureState, setFeatureState] = useState({
        windowHeight: height,
        windowWidth: width,
        imageOffsetY: 1,
    })

    // Lottie File Animation UseEffect
    useEffect(() => {
        const anim =
            data.lottie &&
            lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: data.lottie,
            })
        return () => data.lottie && anim.destroy() // optional clean up for unmounting
    }, [data.lottie, animationContainer])

    // Use hook to run the following code after page is loaded

    useEffect(() => {
        setTimeout(() => {
            getImageOffset()
        }, 1000)

        // this might be a bad idea will see performance hit
        const debouncedHandleResize = debounce(function handleResize() {
            var isNegative = false

            if (data.imageOffsetY < 0) isNegative = true

            var imagewrapperHeight = imageWrapperRef.current.clientHeight
            var wrapperspace = (variationHeight - imageWrapperRef.current.clientHeight) / 2
            var imageHeight = imageRef.current.clientHeight
            var wrapperImageDifference = imagewrapperHeight - imageHeight

            var offsetValue = wrapperImageDifference + wrapperspace

            if (isNegative) offsetValue = wrapperspace

            isBrowser &&
                setFeatureState({
                    windowHeight: window.innerHeight,
                    windowWidth: window.innerWidth,
                    imageOffsetY:
                        window.innerWidth < 1024
                            ? 0
                            : isNegative
                                ? -(-data.imageOffsetY + offsetValue)
                                : data.imageOffsetY + offsetValue,
                })
        }, 400)
        // Listen for window resize and run the debounce resize function above.
        isBrowser && window.addEventListener(`resize`, debouncedHandleResize)
        // Remove the event listener if resizing stopped.
        return () =>
            isBrowser && window.removeEventListener(`resize`, debouncedHandleResize)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // this might be a bad idea. haha
    const getImageOffset = () => {
        if (data.imageOffsetY !== 0 || data.imageOffsetX !== 0) {
            var isNegative = false

            if (data.imageOffsetY < 0) isNegative = true

            var imagewrapperHeight = imageWrapperRef.current.clientHeight
            var wrapperspace = (variationHeight - imageWrapperRef.current.clientHeight) / 2 //was 680, changed height of standard to 600
            var imageHeight = imageRef.current.clientHeight
            var wrapperImageDifference = imagewrapperHeight - imageHeight

            var offsetValue = wrapperImageDifference + wrapperspace

            if (isNegative) offsetValue = wrapperspace

            isBrowser &&
                setFeatureState({
                    windowHeight: window.innerHeight,
                    windowWidth: window.innerWidth,
                    imageOffsetY:
                        window.innerWidth < 1024
                            ? 0
                            : isNegative
                                ? -(-data.imageOffsetY + offsetValue)
                                : data.imageOffsetY + offsetValue,
                })
        }
    }

    var constraintStyle = {
        maxHeight: "auto",
        maxWidth: "auto",
        paddingTop: "inherit",
        paddingBottom: "inherit",
        paddingLeft: "inherit",
        paddingRight: "inherit",
        objectFit: "contain",
    }

    if (data.imageContraint) {
        var maxwidth =
            data.imageContraint.maxWidth > width
                ? "100%"
                : `${data.imageContraint.maxWidth}px`
        var maxHeight =
            data.imageContraint.maxWidth > width
                ? "auto"
                : `${data.imageContraint.maxHeight}px`

        constraintStyle = {
            maxHeight: maxHeight,
            maxWidth: maxwidth,
            paddingTop: `${data.imageContraint.paddingY}px`,
            paddingBottom: `${data.imageContraint.paddingY}px`,
            paddingLeft: `${data.imageContraint.paddingX}px`,
            paddingRight: `${data.imageContraint.paddingX}px`,
            objectFit: "contain",
        }
    }

    var gradientStyle = {}

    if (data.backgroundGradient) {
        gradientStyle = {
            background: `${data.backgroundGradient}`,
        }
    }

    var indexStyle = {};
    if (data.imageZIndex) {
        indexStyle = {
            zIndex: data.imageZIndex,
        }
    }

    var offsetStyle = {}

    if (data.imageOffsetY !== 0 || data.imageOffsetX !== 0) {
        offsetStyle = {
            top: `${featureState.imageOffsetY}px`,
            left: `${data.imageOffsetX}px`,
        }
    }

    //BackgroundGradient
    return (
        <section
            className={classNames(
                "image-feature",
                data.backgroundColour,
                `image-feature--${data.variation}`,
                `image-feature--${data.featureOrder}`
            )}
            style={indexStyle}
        >
            <div className="image-feature__overlay" style={gradientStyle}></div>
            {data.backgroundImage != null && (
                <div
                    className="image-feature__background bg-cover xs:bg-top"
                    style={{
                        backgroundImage: `url(${data.backgroundImage.formats.large.url})`,
                    }}
                ></div>
            )}
            <div className="image-feature__wrapper">
                <div className="image-feature__content">
                    <div className="image-feature__content__inner flex flex-col">
                        {data.breadcrumbTitle != null ? (
                            <div className="image-feature__content__breadcrumbTitle">
                                <BreadcrumbTitle data={data.breadcrumbTitle} />
                            </div>
                        ) : null}
                        <StylisedText data={data.title} type="h3" />
                        <p
                            className={classNames(
                                "image-feature__smalltext",
                                "body--regular",
                                data.textColour
                            )}
                        >
                            {data.Description}
                        </p>
                        {data.button != null ? (
                            <div className="image-feature__btn-wrapper">
                                <ButtonLink
                                    button={data.button}
                                    appearance={data.button.type}
                                    key={data.button.id}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="image-feature__image">
                    <div
                        className={classNames(
                            "image-feature__image__wrapper",
                            data.imageContraint &&
                            data.imageContraint.allowOverflow &&
                            "overflow"
                        )}
                        ref={imageWrapperRef}
                        style={constraintStyle}
                    >
                        <div
                            className="image-feature__image__inner flex items-center justify-center"
                            style={offsetStyle}
                        >
                            {data.image ? (
                                <img
                                    style={
                                        data.imageContraint && {
                                            minWidth: data.imageContraint.minWidth,
                                        }
                                    }
                                    ref={imageRef}
                                    src={data.image?.url}
                                    alt={data.image?.alternativeText ? data.image?.alternativeText : 'Hooli Image'}
                                />
                            ) : (
                                <div ref={animationContainer} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageFeature
