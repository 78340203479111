import React from "react"
import StylizedText from "../elements/stylised-text"
import Image from "../image"
import ButtonLink from "../elements/button-link"

const MultiImage = ({ data }) => {
  if (data.images.length > 3) data.images.length = 3

  return (
    <section className={`multiimage bg-${data.backgroundColour}`}>
      <div className="multiimage__container">
        <div
          className={`multiimage__imagesblock multiimage__imagesblock--${data.alignment}`}
        >
          {data.images.map(image => (
            <div key={image.id}>
              <Image media={image} className="multiimage__image" />
            </div>
          ))}
        </div>
        <div className={`multiimage__textblock`}>
          <div
            className={
              data.images.length === 1
                ? `multiimage__textblock--single-${data.alignment}`
                : `multiimage__textblock--multi`
            }
          >
            <StylizedText data={data.title} type="h3" />
            <p className="multiimage--text gray">{data.description}</p>
            <div className="multiimage__button">
              {data.button && (
                <ButtonLink
                  button={data.button}
                  appearance={data.button.type}
                  key={data.button.id}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MultiImage
