import React from "react"
import StylisedText from "./stylised-text"
import ButtonLink from "./button-link"

import BreadcrumbTitle from "./breadcrumb-title"

const RenderBackground = ({ image }) => {
  return (
    <div className="casestudy-item__background">
      {image.ext === ".mp4" || image.ext === ".webm" ? (
        <div className="videoWrapper">
          <video
            className="video"
            preload
            loop
            autoPlay
            muted
            playsinline="true"
          >
            {image.ext === ".mp4" ? <source src={image.url} type="video/mp4" /> : ''}
                {image.ext === ".webm" ? <source src={image.url} type="video/webm" /> : ''}
          </video>
        </div>
      ) : (
        <div
          className="casestudy-item__background bg-cover xs:bg-top"
          style={{ backgroundImage: `url(${image.formats.large.url})` }}
        ></div>
      )}
    </div>
  )
}

const CaseStudyItem = ({ data }) => {

  return (
    <article className="casestudy-item">
      {data.background !== null ? (
        <RenderBackground image={data.background} colour="black" />
      ) : null}
      <div
        className="casestudy-item__overlay"
        style={
          data.backgroundGradient
            ? { background: data.backgroundGradient }
            : {}
        }
      ></div>

      <div className="casestudy-item__content">
        <BreadcrumbTitle data={data.breadcrumbTitle} />
        <StylisedText data={data.title} type="h3" />
        <p>{data.description}</p>
        <ButtonLink
          button={data.button}
          appearance={data.button.type}
          key={data.button.id}
        />
      </div>
    </article>
  )
}

export default CaseStudyItem
