import React from 'react';
import loadable from '@loadable/component'

const Chart = loadable(() => import('react-apexcharts'))

const StatisticItem = ({ data }) => {

    return (
        <div className="statisticItem">
            <div className="statisticItem__chart-wrapper">
                <Chart
                    options={data.config.options}
                    series={data.config.series}
                    type={data.type}
                    height={250}
                />
            </div>
            <div className="statisticItem__description">{data.description}</div>
        </div>
    )
}

export default StatisticItem;