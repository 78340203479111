import React from "react"
import GridItem from "../elements/grid-item"
import StylizedText from "../elements/stylised-text"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import ChallengeItem from "../elements/challenge-item"

const ChallengeFeaturette = ({ data }) => {
    let styleOverride = {};
    if (data.items && data.items.length / data.itemsPerRow > data.maxNumberOfRows)
        data.items.length = data.itemsPerRow + data.maxNumberOfRows

    console.log(data)


    if (data.backgroundColourOverride) {
        styleOverride = {
            backgroundColor: data.backgroundColourOverride,
        }
    }

    return (
        <section className={`challengefeaturette bg-${data.backgroundColour}`} style={styleOverride}>
            <div className="challengefeaturette__outer">
                <div
                    className={`challengefeaturette__container challengefeaturette--${data.alignment}`}
                >
                    <div
                        className={`challengefeaturette__text-wrap items-center justify-center challengefeaturette__text-wrap--${data.alignment}`}
                    >
                        {data.breadcrumbTitle ? (
                            <BreadcrumbTitle data={data.breadcrumbTitle} />
                        ) : (
                            ""
                        )}
                        {data.title ? <StylizedText data={data.title} type="h3" /> : ""}
                        {data.description ? (
                            <div className="challengefeaturette__description">
                                <p>{data.description}</p>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {data.items ? (
                        <div className={`challengefeaturette__grid-wrap--${data.alignment}`}>
                            <div
                                className={`challengefeaturette__grid challengefeaturette__grid--${data.itemsPerRow}`}
                            >
                                {data.items.map((item, index) =>
                                    item.imageProps || item.lottie ? (
                                        <ChallengeItem data={item} key={index} />
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
    </section >
  )
}

export default ChallengeFeaturette
