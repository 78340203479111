import React from 'react'
import BreadcrumbTitle from '../elements/breadcrumb-title'
import StylizedText from '../elements/stylised-text'
import QuickLink from '../elements/quick-link'

const QuickLinks = ({ data }) => {
    return (
        <section className="quicklinks" style={{ background: data.backgroundGradient }}> 
            <div className="quicklinks__text-wrapper">
                <BreadcrumbTitle data={data.breadcrumbTitle} />
                <StylizedText data={data.title} type="h3" style/>
            </div>
            <div className="quicklinks__option-wrapper">
                {data.links.map((link, index) => (
                    <QuickLink link={link} key={index}/>
                ))}
            </div>
        </section>
    )
}

export default QuickLinks;