import React, { useEffect, createRef, useRef, useState } from "react"
import { debounce } from "@/utils/common"
import classNames from "classnames"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import StylisedText from "../elements/stylised-text"
import ButtonLink from "../elements/button-link"
import lottie from "lottie-web"

const isBrowser = typeof window !== "undefined"

const ImageBenefits = ({ data }) => {
    const imageRef = useRef(null)
    const imageWrapperRef = useRef(null)


    var constraintStyle = {
        maxHeight: "auto",
        maxWidth: "auto",
        paddingTop: "inherit",
        paddingBottom: "inherit",
        paddingLeft: "inherit",
        paddingRight: "inherit",
        objectFit: "contain",
    }

    if (data.imageConstraint) {
        var maxwidth =
            data.imageConstraint.maxWidth > 0
                ? `${data.imageConstraint.maxWidth}px`
                : "100%"
        var maxHeight = "auto";

        constraintStyle = {
            maxHeight: maxHeight,
            maxWidth: maxwidth,
            paddingTop: `${data.imageConstraint.paddingY}px`,
            paddingBottom: `${data.imageConstraint.paddingY}px`,
            paddingLeft: `${data.imageConstraint.paddingX}px`,
            paddingRight: `${data.imageConstraint.paddingX}px`,
            objectFit: "contain",
        }
    }

    //BackgroundGradient
    return (
        <section
            className={classNames(
                "image-benefits",
                data.backgroundColour,
                `image-benefits--imageText`
            )}
        >
            <div className="image-benefits__overlay"></div>
            {data.backgroundImage != null && (
                <div
                    className="image-benefits__background bg-cover xs:bg-top"
                    style={{
                        backgroundImage: `url(${data.backgroundImage.formats.large.url})`,
                    }}
                ></div>
            )}
            <div className="image-benefits__wrapper">
                <div className="image-benefits__content">
                    <div className="image-benefits__content__inner flex flex-col">
                        <StylisedText data={data.Title} type="h3" />
                        <div className="image-benefits__image py-12">
                            <div
                                className={classNames(
                                    "image-benefits__image__wrapper",
                                    data.imageContraint &&
                                    data.imageContraint.allowOverflow &&
                                    "overflow"
                                )}
                                ref={imageWrapperRef}
                                style={constraintStyle}
                            >
                                <div
                                    className="image-benefits__image__inner flex items-center justify-center"
                                >
                                    {data.Image ? (
                                        <img
                                            style={constraintStyle}
                                            ref={imageRef}
                                            src={data.Image?.url}
                                            alt={data.Image?.alternativeText ? data.Image?.alternativeText : 'Hooli Image'}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-benefits__content">
                    <div className="image-benefits__content__inner flex flex-col gap-6 justify-start h-full">
                        {
                            data.ListItem.map((item, index) => <div className="image-benefits__content__benefit">
                                <span className="image-benefits__content__benefit__title">{item.Title}</span>
                                <p>
                                    {item.Description}
                                </p>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageBenefits
