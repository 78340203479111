import React from "react"
import StylizedText from "../elements/stylised-text"

const Declaration = ({ data }) => {
  return (
    <section className="declaration">
      <div
        className="declaration__overlay"
        style={
          data.backgroundGradient ? { background: data.backgroundGradient } : {}
        }
      ></div>
      <div className="declaration__shadowOverlay"></div>
      <div
        className="declaration__background"
        style={{ backgroundImage: `url(${data.backgroundImage.url})` }}
      ></div>
      <div className="declaration__outer">
        <div
          className={`declaration__container declaration__container--${data.alignment}`}
        >
          <div className="declaration__title-wrapper ">
            <div className="declaration__title"><StylizedText data={data.title} type="h2" /></div>
            
          </div>
          <div className="declaration__desc-wrapper">
            <p className="declaration__smalltext">{data.description}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Declaration
