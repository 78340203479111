import React from "react"
import ButtonLink from "../elements/button-link"
import StylisedText from "../elements/stylised-text"
import Video from "../elements/video"
import Image from "../image"
import BreadcrumbTitle from "../elements/breadcrumb-title"

const HeroBackground = ({ data }) => {
  return (
    <>
      {/* Video background */}
      {(data.variation !== "videoPlayer" &&
      data.variation !== "foregroundImage" &&
      (data.background.ext === ".mp4" || data.background.ext === ".webm")) ? (
        <div className="hero__background">
          <div className="videoWrapper">
            <video
              className="video"
              preload="true"
              loop
              muted
              autoPlay
              playsInline
              controls=""
            >
              {data.background.ext === ".mp4" ? <source src={data.background.url} type="video/mp4" /> : ''}
              {data.background.ext === ".webm" ? <source src={data.background.url} type="video/webm" /> : ''}
            </video>
          </div>
        </div>
      ) : // Image or colour background
      data.background && data.background.ext !== ".mp4" ? (
        <div
          className="hero__background bg-cover xs:bg-top"
          style={{
            backgroundImage: `url(${data.background.url})`,
          }}
        ></div>
      ) : (
        <div
          className="hero__background bg-cover xs:bg-top"
          style={{
            backgroundColor: data.backgroundColour
              ? data.backgroundColour
              : "black",
          }}
        ></div>
      )}
    </>
  )
}

const HeroContent = ({ data }) => {
  return (
    <div className="hero__content__inner">
      <div>
        {data.publishDate ? (
          <div className="hero__bodytext mb-5">
            <div className="datePublished">
              {new Date(data.publishDate).toLocaleDateString()}
            </div>
          </div>
        ) : (
          <></>
        )}
        {data.variation === "videoPlayer" ||
        data.variation === "foregroundImage" ? (
          <StylisedText data={data.title} type="h2" />
        ) : (
          <StylisedText data={data.title} />
        )}
        {
            data.subtitle ? <BreadcrumbTitle data={data.subtitle} /> : <></>
        }
        <p
          dangerouslySetInnerHTML={{ __html: data.description }}
          className={
            data.variation === "videoPlayer" ||
            data.variation === "foregroundImage"
              ? "hero__bodytext"
              : "hero__smalltext hero__bodytext mb-8"
          }
        ></p>
      </div>
      {data.buttons.length > 0 && (
        <div
          className={`hero__btn hero__btn--${data.linkOrder} xlg:self-start`}
        >
          {data.buttons.map(button => (
            <ButtonLink
              button={button}
              appearance={button.type}
              key={button.id}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const Hero = ({ data }) => {
  data.overlayGradient = data.overlayGradient && data.overlayGradient.replace(';','')

  if (!data.variation || data.variation === "") {
    data.variation = "standard"
  }

  if (!data.linkOrder || data.linkOrder === "") {
    data.linkOrder = "bottom"
  }

  const overlayStyle = {
    background: `${data.overlayGradient}`,
  }

  return (
    <section className={`hero hero--${data.variation?.toLowerCase()}`}>
      <div className="hero__overlay" style={overlayStyle}></div>
      <HeroBackground data={data} />
      <div className={`hero__container`}>
        <div className={`hero__content__outer`}>
          <HeroContent data={data} />
          {data.variation === "videoPlayer" ? (
            <div className="hero__video">
              {data.videos ? <Video data={data.videos} /> : ""}
            </div>
          ) : (
            ""
          )}
          {data.variation === "foregroundImage" ? (
            <div className="hero__foregroundimg">
              {data.foregroundImage ? (
                <Image media={data.foregroundImage} className="" />
              ) : (
                ""
              )}{" "}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  )
}
export default Hero
