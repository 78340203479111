import React from "react"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import StylisedText from "../elements/stylised-text"
import ButtonLink from "../elements/button-link"

const PromoItem = ({ data }) => {
  return (
    <>
      <div
        className="promoItem__overlay"
        style={
          data.backgroundGradient ? { background: data.backgroundGradient } : {}
        }
      ></div>
      <div
        className="promoItem__banner"
        style={
          data.background
            ? {
                backgroundImage: `url(${data.background.formats.large.url})`,
              }
            : {}
        }
      >
        <div className="promoItem__banner__inner">
          <div className="promoItem__banner__text">
            <BreadcrumbTitle data={data.breadcrumbTitle} />
            <StylisedText data={data.title} type="h3" />
            <div className="promoItem__banner__text__description">
              {data.description}
            </div>
            <div className="promoItem__banner__button">
            <ButtonLink
              button={data.button}
              appearance={data.button.type}
              key={data.button.id}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PromoItem
