import React from "react"
import PropTypes from "prop-types"
import { mediaPropTypes } from "@/utils/types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getStrapiMedia } from "../utils/media"

const Image = ({ media, className, style }) => {

    const isDynamicImage = Boolean(media.localFile__NODE)
    const alt = media.alternativeText || "An image uploaded to Strapi"

    if (isDynamicImage) {
        return (
            <GatsbyImage
                className={className}
                placeholder="none"
                image={getImage(media.localFile__NODE)}
                alt={alt}
                style={style}
            />
        )
    }

    return (
        <img
            src={getStrapiMedia(media.url)}
            alt={alt}
            className={className}
            style={style}
        />
    )
}

Image.propTypes = {
    media: mediaPropTypes,
    className: PropTypes.string
}

export default Image
