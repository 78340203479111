import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CateroriesSelector from "./elements/categories-selector"

const Insights = () => {
  const data = useStaticQuery(query)
  const { edges } = data.allStrapiInsight

  return (
    <section className="xl:container mx-auto py-12 2xl:px-32 xl:px-0 lg:px-0 xs:px-8 ">
      <CateroriesSelector insightsData={edges} />
    </section>
  )
}

export default Insights

const query = graphql`
  query {
    allStrapiInsight(sort: {fields: [publishDate], order: DESC}) {
      edges {
        node {
          id
          slug
          title
          blurb
          publishDate
          categories {
            name
          }
          cardBackground {
            ext
            url
            formats {
              large {
                url
              }
            }
          }
          references {
            link
          }
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
