import React from "react"
import { useState, useEffect } from "react"
import { debounce } from "@/utils/common"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import StylizedText from "../elements/stylised-text"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import Image from "../image"

import "swiper/css/bundle"
import "swiper/css/autoplay"

const isBrowser = typeof window !== "undefined"
const Values = ({ data }) => {
  let height = 0
  let width = 0

  height = isBrowser && window.innerHeight
  width = isBrowser && window.innerWidth
  // See default state of dimensions
  const [featureState, setFeatureState] = useState({
    windowHeight: height,
    windowWidth: width,
    slidesPer: 1.3,
  })

  // Use hook to run the following code after page is loaded
  useEffect(() => {
    setTimeout(() => {
      setSlidesPer(width)
    }, 500)

    // this might be a bad idea will see performance hit
    const debouncedHandleResize = debounce(function handleResize() {
      var _slides = 1.3
      if (isBrowser && window.innerWidth < 640) _slides = 1

      setFeatureState({
        windowHeight: height,
        windowWidth: width,
        slidesPer: _slides,
      })
    }, 400)
    // Listen for window resize and run the debounce resize function above.
    isBrowser && window.addEventListener(`resize`, debouncedHandleResize)
    // Remove the event listener if resizing stopped.
    return () =>
      isBrowser && window.removeEventListener(`resize`, debouncedHandleResize)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // this might be a bad idea
  const setSlidesPer = screenWidth => {
    var _slides = 1.3
    if (screenWidth < 640) {
      _slides = 1
    }

    setFeatureState({
      windowHeight: height,
      windowWidth: width,
      slidesPer: _slides,
    })
  }

  return (
    <section className={`values bg-${data.backgroundColour}`}>
      <div className="values__container flex">
        <div className="values__text-wrapper">
          <BreadcrumbTitle data={data.breadcrumbTitle} />
          <StylizedText data={data.title} type="h3" />
          <p className={`values__description ${data.descriptionColour}`}>
            {data.description}
          </p>
          {data.subheadingStylized ? (
            <StylizedText data={data.subheadingStylized} type="p" />
          ) : (
            ""
          )}
        </div>
        <div className="values__slide-wrapper">
          <div className="values__overlay">
            <div
              className="values__overlay__left"
              style={{ background: data.overlayGradient }}
            ></div>
            <div
              className="values__overlay__right"
              style={{ background: data.overlayGradient }}
            ></div>
          </div>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={0}
            slidesPerView={featureState.slidesPer}
            speed={700}
            autoplay={true}
            loop={true}
            slideActiveClass="true"
            className="values__slide"
          >
            {data.images.map(slide => (
              <SwiperSlide key={slide.id}>
                <div className="values__slide__image-wrapper">
                  <div className="values__slide__image-wrapper__inner">
                    <Image media={slide} className="" />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default Values
