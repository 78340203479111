import React, { useRef } from 'react';
import PlayBtn from '../../images/video-play-button.svg';

const Video = ({ data }) => {

    const videoWrapperRef = useRef(null);
    const videoRef = useRef(null);
    const videoButtonRef = useRef(null);

    const handlePlay = () => {
        videoRef.current.play();
        videoRef.current.controls = true;
        videoWrapperRef.current.classList.remove('video--nocontrols');
        videoButtonRef.current.classList.add('hidden');
    };

    const handlePause = () => {
        videoRef.current.pause();
        videoWrapperRef.current.classList.add('video--nocontrols');
        videoButtonRef.current.classList.remove('hidden');
    };

    const handleToggleVideo = () => (videoRef.current.paused ? handlePlay() : handlePause());


    return (
        <section className="video video--nocontrols" ref={videoWrapperRef}>
            <button
                aria-label="Play Video"
                ref={videoButtonRef}
                className="video__button"
                onClick={handleToggleVideo}
            >
                <PlayBtn className="icon" />
            </button>

            <video
                kind="subtitles"
                ref={videoRef}
                preload="true"
                playsInline={true}
                poster={data.thumbnail.url}
            >
                {data.mp4 ? <source src={data.mp4.url} type="video/mp4" /> : ''}
                {data.ogg ? <source src={data.ogg.url} type="video/ogg" /> : ''}
                {data.webm ? <source src={data.webm.url} type="video/webm" /> : ''}
                <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"></track>
            </video>
        </section>
    );
}

export default Video;