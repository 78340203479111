import React from "react"
import PropTypes from "prop-types"
import Markdown from "react-markdown"

const ArticleContent = ({ data }) => {
  return (
    <div className="article">
      <div className="prose prose-md max-w-none prose-a:text-blue-600">
        {/* TODO: Maybe create another component for RawText */}
        {/* <div dangerouslySetInnerHTML={{ __html: data.content }}></div>  */}
        <Markdown source={data.content} />
      </div>
    </div>
  )
}

ArticleContent.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
  }),
}

export default ArticleContent
