import React from "react"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import StylisedText from "../elements/stylised-text"
import Markdown from "react-markdown"

const TextDescription = ({ data }) => {
  return (
    <section
      className={`textdescription textdescription--bg-${data.backgroundColour} ${data.alignment ? `textdescription--${data.alignment}`: ""}`}
    >
      <div className="textdescription__outer">
        <div
          className={`textdescription__inner textdescription__inner--${data.textColour}`}
        >
          <BreadcrumbTitle data={data.breadcrumbTitle} />
          <StylisedText data={data.title} type="h3" />
          <div className="textdescription__text-wrapper">
            <Markdown source={data.content} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextDescription
