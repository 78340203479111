import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CaseStudyItem from "./elements/casestudy-item"

const CaseStudies = () => {
  const data = useStaticQuery(query)
  const { edges } = data.allStrapiCasestudy

  return (
    <section className="casestudies">
      <div className="casestudies__wrapper">
        {edges.map((study, id) => (
          <CaseStudyItem data={study.node.cardMetadata.promoItem} key={id} />
        ))}
      </div>
    </section>
  )
}

export default CaseStudies

const query = graphql`
  query {
    allStrapiCasestudy {
      edges {
        node {
          id
          title
          slug
          blurb
          metadata {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
          contentSections
          cardMetadata {
            promoItem {
              backgroundGradient
              breadcrumbTitle {
                dashColour
                colour
                id
                title
              }
              background {
                ext
                formats {
                  large {
                    url
                  }
                }
              }
              button {
                id
                newTab
                text
                type
                url
              }
              description
              id
              title {
                colour
                id
                style
                text
              }
            }
            id
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`
