import React from "react"
import PromoItem from "../elements/promo-feature-item"
import classNames from "classnames"

import {  Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"

const RotationalFeature = ({ data }) => {

    return data.promoitems ? (
        <section
            className={classNames(
                "rotationalFeature",
                data.primaryBackgroundColour ? data.primaryBackgroundColour : "white",
            )}
        >
            <div
                className={classNames(
                    "rotationalFeature__background",
                    data.secondaryBackgroundColour
                        ? data.secondaryBackgroundColour
                        : "black"
                )}
            ></div>
            <div className="rotationalFeature__swiper">
                <Swiper
                    modules={[Autoplay, Navigation]}
                    spaceBetween={100}
                    slidesPerView={1}
                    speed={800}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: true,

                    }}
                    navigation={data.promoitems.length > 1 ? true : false}
                    slideActiveClass="true"
                    className="w-full"
                >
                    {data.promoitems.map(item => (
                        <SwiperSlide key={item.id} className="rotationalFeature__slide">
                            <div className="rotationalFeature__outer">
                                <PromoItem data={item} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    ) : (
        <></>
    )
}

export default RotationalFeature
