import React, { useState, useRef, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BreadcrumbTitle from '../elements/breadcrumb-title'
import StylizedText from '../elements/stylised-text'
import { FiArrowRight } from "react-icons/fi"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Modal from "../elements/modal/modal"
import SubscribeModal from "../elements/modal/subscribe-modal"
import EmojiText from "./emoji-text"
import FormComponent from "../elements/forms/form-component"
import axios from "axios"

const TakeAway = ({ data }) => {

    const [formData, setFormData] = useState()
    const [formValues, setFormValues] = useState()

    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [submit, setSubmit] = useState(false)

    const pageQueryData = useStaticQuery(PageQuery)
    const { edges } = pageQueryData.allHubspotForm

    const formRef = useRef()
    const takeawayModalRef = useRef()
    // const recaptchaFooterRef = useRef() (Not used)
    // const cookieModalRef = useRef()

    const windowCheck = () => {
        return typeof window !== `undefined` ? true : false
    }

    const initializeHubspotForm = () => {
        if ('hbspt' in window) {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25395670",
                formId: data.formGuid,
                target: "#takeaway__form"
            });
        } else {
            setTimeout(this.initializeHubspotForm, 500);
        }
    }

    useEffect(() => {
        if (data.formGuid == null) {
            data.formGuid = "807896cd-f62b-40b4-9c80-e839840d168b";
        }
        if (windowCheck()) {
            initializeHubspotForm();
        }
    }, [])

    return (
        <section className="takeaway" style={{ background: data.backgroundGradient }}>
            <div className="takeaway__text-wrapper">
                <BreadcrumbTitle data={data.breadcrumbTitle} />
                <StylizedText data={data.title} type="h3" style />
            </div>
            <div className="takeaway__text body--regular">
                <EmojiText data={{ content: data.description }} />
            </div>
            <div id="takeaway__form" className="formComponent takeaway__option-wrapper">

            </div>
        </section>
    )
}

export default TakeAway;

const PageQuery = graphql`
  query TakeawayPageQuery {
    allHubspotForm(limit: 20) {
      edges {
        node {
          portalId
          guid
          name
          formFieldGroups {
            fields {
              label
              name
              required
              fieldType
              hidden
              placeholder
              options {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`
