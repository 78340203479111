import React from "react"
import ServiceItem from "../elements/service-item"

const ServiceSection = ({ data }) => {
    return (
        <section className="serviceSection">
            { data.items.map(item => (
                <ServiceItem data={item} key={item.id} />
            ))}
        </section>
    )
}

export default ServiceSection
