import React from "react"
import LineBreak from "../elements/line-break"

const SuccessCriteria = ({ data }) => {
  return (
    <section className="successcriteria">
      <div className="successcriteria__outer">
        <div className="successcriteria__inner">
          {data.criteria && (
            <>
            <div className="line--top"><LineBreak data={data.linebreak} /></div>
              <div className="successcriteria__heading">Success Criteria:</div>
              <div className="successcriteria__criteria">{data.criteria}</div>
            </>
          )}
          <div className="line--middle"><LineBreak data={data.linebreak} /></div>
          <div className="successcriteria__heading">Salary - </div>
          <div className="successcriteria__salary">{data.salary}</div>
          <div className="line--bottom"><LineBreak data={data.linebreak} /></div>
        </div>
      </div>
    </section>
  )
}

export default SuccessCriteria
