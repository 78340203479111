import React from "react"
import Markdown from "react-markdown"
import StylisedText from "../elements/stylised-text"
import LineBreak from "../elements/line-break"

const JobDescription = ({ data }) => {
  return (
    <section className="jobdescription">
      <div className="jobdescription__outer">
        <div className="jobdescription__inner">
          <div className="jobdescription__title">
            <StylisedText data={data.title} type="h3" />
          </div>
          <div className="jobdescription__content prose prose-md prose-a:text-blue-600">
            <Markdown source={data.content} />
          </div>
          {data.linebreak && <LineBreak data={data.linebreak} />}
        </div>
      </div>
    </section>
  )
}

export default JobDescription
