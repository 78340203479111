import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import Hero from "./hero"

import "swiper/css/bundle"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import "../../styles/scss/components/section/hero-slider.scss"

const HeroSlider = ({ data }) => {
  return (
    <section className="hero-slider">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        speed={1500}
        autoplay={true}
        loop={true}
        pagination={{ clickable: true }}
        slideActiveClass="true"
      >
        {data.slide.map(slide => (
          <SwiperSlide key={slide.id}>
            <Hero data={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default HeroSlider
