import React from "react"
import StylisedText from "../elements/stylised-text"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import classNames from "classnames"
import FlexGrid from "../elements/flex-grid"

const FlexGridFeaturette = ({ data }) => {
  return (
    <section
      className={classNames(
        "flexgridfeat",
        !data.backgroundColourOverride && `bg-${data.backgroundColour}`
      )}
      style={ data.backgroundColourOverride ? {backgroundColor: data.backgroundColourOverride} : {}}
    >
      <div className="flexgridfeat__container">
        <div className="flexgridfeat__text">
          <div className="flexgridfeat__text__inner">
            <BreadcrumbTitle data={data.breadcrumbTitle} />
            <StylisedText data={data.title} type="h3" />
          <div className="flexgridfeat__text__description">
            {data.description}
          </div>
          </div>
          
        </div>
        {data.grids && (
          <div className="flexgridfeat__grids">
            {data.grids.map(
              grid => <FlexGrid data={grid} key={grid.id} />
            )}
            {data.gridtext && (
              <div className="flexgridfeat__grids__gridtext">{data.gridtext}</div>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default FlexGridFeaturette
