import React, { useEffect, createRef } from "react"
import Image from "../image"
import lottie from "lottie-web"

const GridItem = ({ data }) => {
  const animationContainer = createRef()

  const item = () => {
    return (
      <div className="flex flex-col items-center justify-center"
        style={{
          minWidth: `${
            data.imageProps.minWidth ? data.imageProps.minWidth : "150"
          }px`,
          minHeight: `${
            data.imageProps.minHeight ? data.imageProps.minHeight : "100"
          }px`,
        }}
      >
        {data.image || data.lottie ? (
          <div
            className={`flex self-${data.imageProps.alignmentX}  flex justify-center items-center`}
            style={{
              height: `${data.imageProps.maxHeight}px`,
              maxWidth: `${data.imageProps.maxWidth}px`,
              maxHeight: `${data.imageProps.maxHeight}px`,
            }}
          >
            {data.image ? (
              <Image
                media={data.image}
                className={`flex self-${data.imageProps.alignmentX}`}
                style={{
                  maxWidth: `${data.imageProps.maxWidth}px`,
                  maxHeight: `${data.imageProps.maxHeight}px`,
                }}
              />
            ) : (
              <div ref={animationContainer} />
            )}
          </div>
        ) : (
          ""
        )}
        <div className="griditem__text-wrap" style={{maxWidth: `${ data.imageProps.maxWidth <= 150 ? (data.imageProps.maxWidth + 60) : (data.imageProps.maxWidth + 10) }px`,}} >
          {data.title ? (
            <p
              className={`griditem__text-wrap__title griditem__text-wrap__title--${data.titleColour}`}
            >
              {data.title}
            </p>
          ) : (
            ""
          )}
          {data.description ? (
            <p
              className={`griditem__text-wrap__description griditem__text-wrap__description--${data.descriptionColour}`}
            >
              {data.description}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    const anim =
      data.lottie &&
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: data.lottie,
      })
    return () => data.lottie && anim.destroy() // optional clean up for unmounting
  }, [animationContainer,data.lottie])

  return (
    <div className={`griditem`}>
      {data.link ? (
        <a href={data.link} target="_blank" rel="noreferrer">
          {item()}
        </a>
      ) : (
        item()
      )}
    </div>
  )
}

export default GridItem
