import React from "react"
import ButtonLink from "../elements/button-link"
import StylisedText from "../elements/stylised-text"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import { Card } from "../elements/card"

import backgroundImg from "@/images/stars_universe.png"

const NeedOurHelp = ({ data }) => {
  
  return (
    <section className="needOurHelp">
      {data.backgroundColourOverride ? (
        <div
          className="needOurHelp__overlay"
          style={{ backgroundColor: data.backgroundColourOverride }}
        ></div>
      ) : (
        <div
          className={`needOurHelp__overlay bg-${data.backgroundColour}`}
        ></div>
      )}
      <div
        className="needOurHelp__bg-img"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <div className="needOurHelp__outer">
          <div className="needOurHelp__inner">
            <BreadcrumbTitle data={data.breadcrumbTitle} />
            <StylisedText data={data.title} type="h3" />
            <div className="flex w-full justify-center">
              <div className="card__wrapper">
                {data.cards.map((cardData, index) => (
                  <Card data={cardData} key={index} />
                ))}
              </div>
            </div>
            {data.link.map(button => (
              <ButtonLink
                button={button}
                appearance={button.type}
                key={button.id}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default NeedOurHelp
