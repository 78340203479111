import React, { useState } from "react"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import { InsightCard } from "./card"

const CateroriesSelector = ({ insightsData }) => {
  const [filterCategoryName, setFilterCatgegoryName] = useState("ALL")
  const [numCategoriesToShow, setNumCategoriesToShow] = useState(5)
  const [active, setActive] = useState()
  const [showMoreText, setShowMoreText] = useState("More...")

  const data = useStaticQuery(query)
  const { edges } = data.allStrapiCategory

  let categoryArray = [{ name: "ALL" }]

  edges.map(category => (
    category.node.insights.length > 0 && categoryArray.push(category.node)
  ))
  categoryArray = categoryArray.sort((a, b) => {
    return a.order - b.order
  })

  const onShowMoreClick = () => {
    if (showMoreText === "More...") {
      setShowMoreText("Less...")
      setNumCategoriesToShow(categoryArray.length)
    }
    if (showMoreText === "Less...") {
      setShowMoreText("More...")
      setNumCategoriesToShow(5)
    }
  }

  return (
    <div className="categoriesSelector">
      <div className="categoriesSelector__outer">
        <div className="categoriesSelector__categories">
          {categoryArray.slice(0, numCategoriesToShow).map((tag, index) => (
            <button
              key={index}
              className={classNames(
                active === tag.name ? "activeTag" : "tags__tag__text",
                "tags__tag m-2"
              )}
              id={tag.name}
              onClick={event => {
                setFilterCatgegoryName(tag.name)
                setActive(event.target.id)
              }}
            >
              {tag.name}
            </button>
          ))}
          {categoryArray.length >= 5 && (
            <div
              className={classNames("tags__tag__text", "tags__tag m-2")}
              onClick={onShowMoreClick}
              onKeyUp={onShowMoreClick}
              role="button"
              tabIndex={0}
            >
              {showMoreText}
            </div>
          )}
        </div>
        <div className="categoriesSelector__grid">
          {insightsData
            .filter(insight =>
              filterCategoryName !== "ALL"
                ? insight?.node?.categories?.some(
                    val => val.name === filterCategoryName
                  )
                : true
            )
            .map(insight => (
              <div className="item" key={insight.id}>
                <InsightCard data={insight.node} />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default CateroriesSelector

const query = graphql`
  query {
    allStrapiCategory {
      edges {
        node {
          slug
          name
          order
          insights {
            id
          }
        }
      }
    }
  }
`
