import React from "react"
import StylisedText from "../elements/stylised-text"
import Image from "../image"

const JobRequirements = ({ data }) => {
  const requirementsList = () => {
    return (
      <ul>
        {data.requirements.map(req => (
          <li key={req.id}>{req.text}</li>
        ))}
      </ul>
    )
  }

  return (
    <section className="jrequirements">
      <div className="jrequirements__outer">
        <div className="jrequirements__inner">
          <div
            className={`jrequirements__image jrequirements__image--${data.order}`}
          >
            {data.image && (
              <div className="jrequirements__image__image-wrap">
                <Image
                  media={data.image}
                  className="jrequirements__image__img"
                  style={{}}
                />
              </div>
            )}
          </div>
          <div
            className={`jrequirements__text jrequirements__text--${data.order}`}
          >
            {data.title.length > 0 && (
              <div className="jrequirements__text__title">
                <StylisedText data={data.title} type="h3" />
              </div>
            )}
            {data.subtitle && (
              <div className="jrequirements__text__subtitle">
                {data.subtitle}
              </div>
            )}
            {data.requirements && (
              <div className="jrequirements__text__requirements">
                {requirementsList()}
              </div>
            )}
            {data.note && (
              <div className="jrequirements__text__note">
                <span className="intro">NOTE: </span>
                <span>{data.note}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobRequirements
