import React from "react"
import GridItem from "../elements/grid-item"

const FlexGrid = ({ data }) => {
  return (
    <div className="flexgrid">
      {data.description && (
        <div className="flexgrid__description">{data.description}</div>
      )}
      <div className="flexgrid__grid">
        {data.items &&
          data.items.map(item =>
            item.imageProps || item.lottie ? <GridItem data={item} key={item.id} /> : ""
          )}
      </div>
    </div>
  )
}

export default FlexGrid
