import React from "react"
import ButtonLink from "../elements/button-link"
import StylisedText from "../elements/stylised-text"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import { InsightCard } from "../elements/card"

const InsightRotationalBanner = ({ data }) => {
  return (
    <section className="insight-rotational-banner">
      <BreadcrumbTitle data={data.breadcrumbTitle} />
      <StylisedText data={data.title} type="h3" />
      {data.description != null && (
        <p className="insight-rotational-banner__description body-light">
          {data.description}
        </p>
      )}

      <div className="flex w-full justify-center">
        <div className="card__wrapper">
          {data.insights.map((insight, index) => (
            <InsightCard data={insight} key={index} />
          ))}
        </div>
      </div>
      <ButtonLink button={data.link} appearance={data.link.type} />
    </section>
  )
}

export default InsightRotationalBanner
