import React from "react"
import Image from "../image"
import Markdown from "react-markdown"

const InsightsImage = ({ data }) => {
  const imageOrder = {
    imageTop: "flex-col",
    imageRight: "flex-row-reverse",
    imageBottom: "flex-col-reverse",
    imageLeft: "flex-row",
  }
  const imageWidth = {
    quarter: "w-1/4",
    half: "w-1/2",
    full: "w-full",
  }

  const marginCalculation = () => {
    const imageMargin = {
      imageTop: "mb-8",
      imageRight: "ml-16",
      imageBottom: "mt-16",
      imageLeft: "mr-16",
    }
    return imageMargin[data.order] || imageMargin["imageLeft"]
  }

  const imagePlacement = () => {
    let classname = imageOrder[data.order]
    let margin = marginCalculation()
    if (
      data.imageWidth === "full" &&
      data.order !== ("imageTop" || "imageBottom")
    ) {
      classname = imageOrder["imageTop"]
      margin = "mb-8"
    }

    return (
      <div className={`flex ${classname}`}>
        <div className={`${imageWidth[data.imageWidth]} ${margin} insightImage`}>
          <Image media={data.image} className={"w-full insightImage__image"}/>
        </div>
        {data.imageText && (
          <div>
            <Markdown source={data.imageText} />
          </div>
        )}
      </div>
    )
  }

  return (
    <section className="container mx-auto py-12 max-w-screen-lg px-0 lg:px-10 md:px-14 sm:px-12 xs:px-10">
      {imagePlacement()}
    </section>
  )
}

export default InsightsImage
