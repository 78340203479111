import React from "react"
import Hero from "@/components/sections/hero"
import LargeVideo from "@/components/sections/large-video"
import RichText from "./sections/rich-text"
import ArticleContent from "./sections/article-content"
import NeedOurHelp from "./sections/need-our-help"
import GetInTouch from "./sections/get-in-touch"
import HeroSlider from "./sections/hero-slider"
import VideoFeature from "./sections/video-feature"
import Testimonials from "./sections/testimonials"
import ImageFeature from "./sections/image-feature"
import InsightRotationalBanner from "./sections/insights-rotational-banner"
import PlatformCloud from "./sections/platform-cloud"
import Declaration from "./sections/declaration"
import Insights from "./insights"
import CaseStudies from "./casestudies"
import MultiImage from "./sections/multi-image"
import GridFeaturette from "./sections/grid-featurette"
import CaseStudyRotationalBanner from "./sections/casystudy-rotational-banner"
import StatisticGrid from "./sections/statistic-grid"
import QuickLinks from "./sections/quicklinks"
import Values from "./sections/values"
import TakeAway from "./sections/take-away"
import TextFeature from "./sections/text-feature"
import Careers from "./careers"
import ServiceSection from "./sections/service-section"
import JobRequirements from "./sections/job-requirements"
import InsightsImage from "./sections/insights-image"
import JobDescription from "./sections/job-description"
import SuccessCriteria from "./sections/success-criteria"
import TextBlock from "./sections/text-block"
import RotationalFeature from "./sections/rotational-feature"
import FlexGridFeaturette from "./sections/flex-grid-featurette"
import ImageBenefits from "./sections/image-benefits"
import ChallengeFeaturette from "./sections/challenge-featurette"

// Map Strapi sections to section components
const sectionComponents = {
  "sections.hero-slider": HeroSlider,
  "sections.hero": Hero,
  "sections.need-our-help": NeedOurHelp,
  "sections.get-in-touch": GetInTouch,
  "sections.video-feature": VideoFeature,
  "sections.image-feature": ImageFeature,
  "sections.testimonials": Testimonials,
  "sections.large-video": LargeVideo,
  "sections.rich-text": RichText,
  "sections.article-content": ArticleContent,
  "sections.platform-cloud": PlatformCloud,
  "layout.insights": Insights,
  "sections.declaration": Declaration,
  "sections.statistic-grid": StatisticGrid,
  "layout.casestudies": CaseStudies,
  "sections.insight-rotational-banner": InsightRotationalBanner,
  "sections.multi-image": MultiImage,
  "sections.grid-featurette": GridFeaturette,
  "sections.casestudy-rotational-banner": CaseStudyRotationalBanner,
  "sections.quick-links": QuickLinks,
  "sections.values": Values,
  "sections.take-away": TakeAway,
  "sections.text-feature": TextFeature,
  "layout.careers": Careers,
  "sections.service-section": ServiceSection,
  "insights.image-content": InsightsImage,
  "careers.job-requirements": JobRequirements,
  "careers.job-description": JobDescription,
  "careers.success-criteria": SuccessCriteria,
  "sections.text-block": TextBlock,
  "sections.rotational-feature": RotationalFeature,
  "sections.flex-grid-featurette": FlexGridFeaturette,
  "sections.image-benefits": ImageBenefits,
  "sections.challenge-featurette": ChallengeFeaturette,
}

// Display a section individually
const Section = ({ sectionData }) => {
    console.log(sectionData)
  // Prepare the component
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component]

  if (!SectionComponent) {
    // No matching component for this page section
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} />
}

// Display the list of sections
const Sections = ({ sections }) => {
  return (
    <div className="flex flex-col">
      {sections.map((section, i) => (
        <Section
          sectionData={section}
          key={`${section.strapi_component}${(section.id, i)}`}
        />
      ))}
    </div>
  )
}

export default Sections
