import React from 'react'
import { useState } from 'react';
import StylisedText from '../elements/stylised-text'
import ButtonLink from './button-link';
import classNames from "classnames";

const ServiceItem = ({ data }) => {

    const [hover, setHover] = useState(false);

    return (
        <div className="serviceItem">
            <div className="serviceItem__container"
                onMouseOver={() => setHover(true)}
                onFocus={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                onBlur={() => setHover(false)}
                role="button"
                aria-label="Service"
                tabIndex={0}
            >
                <div className={classNames(
                    "serviceItem__gradient",
                    "wrapper",
                    data.backgroundGradient,
                    {
                        "serviceItem__gradient--fade": hover,
                    },
                )}></div>
                <div className="serviceItem__background">
                    {data.background.ext === '.mp4' || data.background.ext === ".webm" ?
                        <div className="videoWrapper">
                            <video className="video" preload loop autoPlay muted playsinline="true">
                                {data.background.ext === ".mp4" ? <source src={data.background.url} type="video/mp4" /> : ''}
                                {data.background.ext === ".webm" ? <source src={data.background.url} type="video/webm" /> : ''}
                            </video>
                        </div>
                        :
                        <div className="wrapper" style={data.background ? { backgroundImage: `url(${data.background.formats.large.url})` } : {}}></div>
                    }
                </div>
                <ServiceItemContent content={data} />
            </div>
        </div>
    )
}

export default ServiceItem;

const ServiceItemContent = ({ content }) => {
    return (
        <div className="serviceItem__content__outer">
            <div className="serviceItem__content__inner">
                <div className="title-wrap">
                    <StylisedText data={content.title} type="h5" />
                </div>
                <p className="description">{content.description}</p>
                <div className="btn">
                    <ButtonLink
                        button={content.link}
                        appearance={content.link.type}
                        key={content.link.id}
                    />
                </div>
            </div>
        </div>
    )
}