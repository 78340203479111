import React from 'react';
import classNames from "classnames";
import Video from '../elements/video';
import BreadcrumbTitle from '../elements/breadcrumb-title';
import StylisedText from '../elements/stylised-text';
import ButtonLink from '../elements/button-link';

const VideoFeature = ({ data }) => {

    return (
        <section className={classNames(
            "video-feature",
            `video-feature--${data.featureOrder}`,
            `bg-${data.backgroundColour}`
        )}>
            <div className="video-feature__container">
                <div className="video-feature__videowrapper">
                    <Video data={data.video} />
                </div>
                <div className="video-feature__textwrapper">
                    <div className="flex flex-col md:items-start text-left self-center">
                        <BreadcrumbTitle data={data.breadcrumbTitle} />
                        <StylisedText data={data.title} type="h3" />
                        {
                            data.description != null &&
                                <p className="video-feature__textwrapper__description body-light">
                                    {data.description}
                                </p>
                        }
                        {
                            data.button != null &&
                                <div className="video-feature__button">
                                    <ButtonLink
                                        button={data.button}
                                        appearance={data.button.type}
                                        key={data.button.id}
                                    />
                                </div>
                        }

                    </div>
                </div>
            </div>

        </section>
    )
}

export default VideoFeature;